import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import nl from './nl.json';

export const locales = { de, en, es, fr, it, nl };

export type Locale = keyof typeof locales;

export default locales;
